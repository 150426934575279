import React, { useLayoutEffect } from 'react';
import Gen from 'random-seed';

import './RouletteWheel.css';

type RouletteWheelProps = {
  onEnd: (i: number) => any,
  data: any[],
}

function RouletteWheel({ onEnd, data }: RouletteWheelProps) {

  useLayoutEffect(() => {
    const rand = Gen.create();

    var spinner;
    $(window).ready(function () {
      const rw = (window as any).RouletteWheel;
      spinner = new rw($('.roulette'), data, (range: number) => rand(range));
      spinner.render();
      spinner.bindEvents();

      spinner.on('spin:start', function () {
        console.log('spin start!');
      });
      spinner.on('spin:end', function (r: any) {
        console.log('spin end! -->' + r._index);
        onEnd(r._index);
      });

    })
  })
  return (
    <div className="main">
      <div className="roulette">
        <div className="spinner"></div>
        <div className="shadow"></div>
        <div className="markers">
          <div className="triangle">

          </div>
        </div>
        <div className="button">
          <span>Spin</span>
        </div>
      </div>
    </div>

  );
}

export default RouletteWheel;
