import React, { useEffect, useState, useRef } from 'react';
import RouletteWheel from './RouletteWheel';
import { confirmAlert } from 'react-confirm-alert';
import { AppConfigurationClient } from "@azure/app-configuration";
import 'react-confirm-alert/src/react-confirm-alert.css';
import NextMonday from './next-monday';
import './App.css';

function App() {
  const connectionString = "Endpoint=https://m3roulette.azconfig.io;Id=HsxV-l8-s0:NQNNc6jwX/n7yTf9Cwpx;Secret=HWcSvq5b41MAzc0QLLvtAhvyeAuqfG0atHtohN1hTJw=";
  const client = new AppConfigurationClient(connectionString);

  const [data, setData] = useState<any>();
  const clientContainer = useRef<AppConfigurationClient>(client);

  useEffect(() => {
    async function loadData() {
      const daresayerIterator = clientContainer.current.listConfigurationSettings();
      const data = [];
      let i = 0;
      for await (const daresayer of daresayerIterator) {
        if (daresayer.value === 'hide') {
          continue;
        }
        // revisions are just a configuration setting at a particular point in time
        data.push({ id: daresayer.key, color: i++ % 2 ? '#0073E6' : '#8FC7FD', text: daresayer.key, ikon: daresayer.value ? 'check_circle' : null });
      }

      if (data.length % 2 !== 0) {
        data.push({ id: 'ASTA', color: '#0073E6', text: 'Asta &#128054;', ikon: 'check_circle' });
      }

      setData(data);
    }

    loadData();
  }, []);

  if (data === undefined) {
    return <div className="lds-ripple"><div></div><div></div></div>;
  }

  return (
    <RouletteWheel data={data} onEnd={(i: number) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='confirm-box'>
              <span className="title">And our next host is:</span>
              <p className="content">{data[i].id}</p>
              <span className="sub-title">Do you accept the mission?</span>
              <div className="react-confirm-alert-button-group">
                <button onClick={onClose}>No</button>
                <button
                  onClick={() => {
                    clientContainer.current.setConfigurationSetting({
                      key: data[i].id,
                      value: NextMonday(),
                    });
                    onClose();
                  }}
                >
                  Yes
              </button>
              </div>
            </div>
          );
        }
      });
    }} />
  );
}

export default App;
