import moment from "moment";

export default function NextMonday(): string {
    const dayINeed = 1; // for Monday
    const today = moment().isoWeekday();
    var nextFriday;
    // if we haven't yet passed the day of the week that I need:
    if (today < dayINeed) {
      // then just give me this week's instance of that day
      nextFriday = moment().isoWeekday(dayINeed);
    } else {
      // otherwise, give me *next week's* instance of that same day
      nextFriday = moment().add(1, 'weeks').isoWeekday(dayINeed);
    }
    return nextFriday.format('LL');
  }